import $ from 'jquery';

function modifyScheduleCallClick() {
  let $callLink = $('#st-schedule-call-link');
  $callLink.on('click', () => {
    window.open($callLink.attr('href'));
    location.href = '/dashboard';
  });
}

export default () => {
  if (!$('#st-schedule-call-link').length) return;
  modifyScheduleCallClick();
};
