import $ from 'jquery';
import { debounce } from 'throttle-debounce';
import 'selectize';

let xhr;
const EDIT_DELAY = 1000;

function loadSchools() {
  var state = $('#submitter_state').val();
  var autofillValue = $('#submitter_state-selectized').val();
  if (state.length == 0 && state !== autofillValue) {
    var selectState = $('#submitter_state')[0].selectize;
    var options = selectState.options;
    var autofillState = '';
    for (var key in options) {
      if (
        options.hasOwnProperty(key) && // eslint-disable-line no-prototype-builtins
        options[key].text === autofillValue
      ) {
        autofillState = options[key].value;
      }
    }
    if (autofillState) {
      selectState.setValue(autofillState, true);
    } else {
      $('#submitter_state-selectized').val('');
      selectState.updatePlaceholder();
    }
    state = autofillState;
  }
  var city = $('#submitter_city').val().trim();
  var selectSchool = $('#submitter_school_name')[0].selectize;
  if (state.length == 0 || city.length == 0) {
    selectSchool.disable();
    selectSchool.clearOptions();
    return;
  }
  selectSchool.disable();
  selectSchool.clearOptions();
  selectSchool.load(function (callback) {
    xhr && xhr.abort();
    xhr = $.getJSON('/api/apw_schools', {
      city: city,
      state: state,
    })
      .done(function (data) {
        selectSchool.enable();
        callback(data);
      })
      .fail(function (jqxhr, textStatus, error) {
        var err = textStatus + ', ' + error;
        console.log('Request Failed: ' + err);
        selectSchool.enable();
        callback();
      });
  });
}

function hideSchoolSection() {
  $('.pw-form__school').hide();
  $('.pw-form__school .u-form--required').prop('required', false);
  $('#submitter_state')[0].selectize.disable();
  $('#submitter_school_name')[0].selectize.disable();
}

function showSchoolSection() {
  var selectSchool = $('#submitter_school_name')[0].selectize;
  $('.pw-form__school').show();
  $('.pw-form__school .u-form--required').prop('required', true);
  $('#submitter_state')[0].selectize.enable();
  if (!$.isEmptyObject(selectSchool.options)) selectSchool.enable();
}

function initHomeSchool() {
  var $homeSchool = $('#submitter_home_school');
  if (!$homeSchool.length) return;
  if ($homeSchool.prop('checked')) hideSchoolSection();
  $homeSchool.change(function () {
    var bChecked = $(this).prop('checked');
    bChecked ? hideSchoolSection() : showSchoolSection();
  });
}

function initPublicSchool() {
  $('input:radio[name="submitter[school_type]"]').change(function () {
    if (this.checked && this.value == '2') {
      $('.o-section--public-school-info').show();
    } else {
      $('.o-section--public-school-info').hide();
    }
  });
  if ($('#submitter_school_type_2').is(':checked')) {
    $('.o-section--public-school-info').show();
  }
}

function initSchoolSelect() {
  $('#submitter_state').selectize({
    closeAfterSelect: true,
    onChange: loadSchools,
  });
  $('#submitter_city')
    .off('input')
    .on('input', debounce(EDIT_DELAY, loadSchools));
  $('#submitter_state-selectized').attr('autocomplete', 'none');
  var selectSchool = $('#submitter_school_name').selectize({
    create: true,
    valueField: 'value',
    labelField: 'value',
    searchField: ['value'],
  });
  if ($('#submitter_school_name').length && !selectSchool.val().length) {
    selectSchool[0].selectize.disable();
  }
}

const initializeSubmitForm = () => {
  if (!$('.o-page--apw-submit-form').length) return;
  initSchoolSelect();
  initHomeSchool();
  initPublicSchool();
  $('.u-selectize').selectize();
};

export default initializeSubmitForm;
