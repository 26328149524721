import $ from 'jquery';

const initializeAnnouncements = () => {
  const elem = document.getElementById('dismiss-dashboard-announcements');
  if (!elem) return;
  elem.onclick = () => {
    const csrfToken = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
      method: 'POST',
      url: '/dashboard/dismiss_announcements',
      headers: {
        'X-CSRF-TOKEN': csrfToken,
      },
    });
  };
};

export default () => {
  initializeAnnouncements();
};
