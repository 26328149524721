import $ from 'jquery';

const initializeVideoPlayerPopup = () => {
  const cseVideoPlayer = document.getElementById('cseVideoPlayer');
  const cseVideoPlayerIFrame = document.getElementById('cseVideoPlayerIFrame');
  if (!cseVideoPlayer) return;
  $('.st-cse-video-player-button').each(function () {
    $(this).on('click', () => {
      while (cseVideoPlayer.firstChild) {
        cseVideoPlayer.removeChild(cseVideoPlayer.firstChild);
      }
      while (cseVideoPlayerIFrame.firstChild) {
        cseVideoPlayerIFrame.removeChild(cseVideoPlayerIFrame.firstChild);
      }
      cseVideoPlayer.hidden = true;
      cseVideoPlayerIFrame.hidden = true;

      // Set title
      const title = $(this).data('title');
      $('#cseVideoPlayerTitle').text(title);

      if ($(this).data('url').endsWith('.mpd')) {
        cseVideoPlayerIFrame.hidden = false;

        cseVideoPlayerIFrame.src = $(this).data('url');
      } else {
        cseVideoPlayer.hidden = false;

        // Set url sources
        const source = document.createElement('source');
        source.src = $(this).data('url');

        const altSource = document.createElement('source');
        altSource.src = $(this).data('backup-url');

        cseVideoPlayer.appendChild(source);
        cseVideoPlayer.appendChild(altSource);
        cseVideoPlayer.load();
        cseVideoPlayer.play();
      }
    });
  });
  document
    .getElementById('cseVideoPlayerModal')
    .addEventListener('hidden.bs.modal', () => {
      cseVideoPlayer.pause();
      cseVideoPlayerIFrame.src = '';
    });
};

export default () => {
  initializeVideoPlayerPopup();
};
