import $ from 'jquery';
import { Modal } from 'bootstrap';

const ratings_given = [];
const tokens_chosen = [];
const names_chosen = [];
let course_count = 0;

const checkSubmitReady = () => {
  if (ratings_given.every((r) => r) && tokens_chosen.every((c) => c))
    $('#reboot_survey_submit').attr('disabled', false);
};

function handleStarRating() {
  const course_num = $(this).parent().parent().attr('data-course-num');
  const rating = Number($(this).data('rating'));
  ratings_given[course_num] = rating;

  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      $(
        `[data-course-num='${course_num}'] .u-st-star-rating[data-rating='${i}']`
      ).addClass('active');
    } else {
      $(
        `[data-course-num='${course_num}'] .u-st-star-rating[data-rating='${i}']`
      ).removeClass('active');
    }
  }
  $(`input[name='course-${course_num}-rating']`).val(rating);
  checkSubmitReady();
}

function handleCourseSelect(val) {
  const course_num = $(this.$wrapper).parent().attr('data-course-num');
  const course_name = $(`#reboot_courses_datalist option[value='${val}']`).attr(
    'data-course-name'
  );
  tokens_chosen[course_num] = val;
  names_chosen[course_num] = course_name;
  checkSubmitReady();
}

const handleSubmitSurvey = (e) => {
  e.preventDefault();
  const csrfToken = $('meta[name="csrf-token"]').attr('content');
  const data = {
    names_chosen: names_chosen,
    tokens_chosen: tokens_chosen,
    ratings_given: ratings_given,
    remarks: [],
  };

  for (let i = 0; i < course_count; i++) {
    data.remarks.push($(`textarea[name="course-${i}-remarks`).val());
  }

  $.ajax({
    method: 'POST',
    url: '/dashboard/reboot_survey',
    data: data,
    headers: {
      'X-CSRF-TOKEN': csrfToken,
    },
  }).done(() => {
    $('#reboot_survey_content').hide();
    $('#reboot_survey_success_message').show();
  });
};

const addCourseToSurvey = () => {
  const course_num = course_count++;
  ratings_given.push(false);
  tokens_chosen.push(false);
  names_chosen.push('');
  const container = $('#reboot_survey_courses');
  const options = $('#reboot_courses_datalist');
  const stars = $('#reboot_star_rating');
  const course_frame = `
<div class="u-reboot-course-card">
  <div data-course-num=${course_num} class="d-flex justify-content-between mb-3">
    <div class="u-st-txt--p-medium me-5">Select your course: <span class="text-blue_dark">*</span></div>
    <select class="flex-grow-1" name="course-${course_num}-id">
      ${options.html()}
    </select>
  </div>
  <div class="d-flex justify-content-between my-3 pt-3">
    <div class="u-st-txt--p-medium me-5">Rate your experience: <span class="text-blue_dark">*</span></div>
    <div data-course-num=${course_num} class="d-flex justify-content-between gap-4">
      ${stars.html()}
      <input type="hidden" name="course-${course_num}-rating"></input>
    </div>
  </div>
  <div class="mt-3 pt-3">
    <div class="u-st-txt--p-medium mb-3">Please share any additional thoughts below (optional):</div>
    <textarea rows="3" name="course-${course_num}-remarks" placeholder="Enter your comments"></textarea>
  </div>
</div>`;
  container.append(course_frame);
  $(`select[name="course-${course_num}-id"]`).selectize({
    onChange: handleCourseSelect,
  });
  $(`[data-course-num="${course_num}"] .u-st-star-rating`).on(
    'click',
    handleStarRating
  );
  $('#reboot_survey_submit').attr('disabled', true);
};

const initializeRebootSurveyPopup = () => {
  let elem = document.getElementById('reboot_survey_popup');
  if (!elem) return;
  let myModal = new Modal(elem);
  $('#reboot_survey_success_message').hide();
  addCourseToSurvey();
  $('#reboot_survey_submit').on('click', handleSubmitSurvey);
  $('#reboot_survey_add_course').on('click', addCourseToSurvey);
  myModal.show();
};

export default () => {
  initializeRebootSurveyPopup();
};
