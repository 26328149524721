import $ from 'jquery';
import * as pdfjsLib from 'pdfjs-dist';

const PdfjsWorker = require('worker-loader?esModule=false&filename=[name].[contenthash].js!pdfjs-dist/build/pdf.worker.js');

if (typeof window !== 'undefined' && 'Worker' in window) {
  pdfjsLib.GlobalWorkerOptions.workerPort = new PdfjsWorker();
}

const initializeSlideAsPDF = () => {
  if (!$('.st-slide-media-pdf').length) return;

  $('.st-slide-media-pdf').each(function () {
    let pdfDoc = null,
      pageNum = 1,
      pageRendering = false,
      pageNumPending = null,
      scale = 1.5,
      canvas = $(this).find('canvas')[0],
      canvasContext = canvas.getContext('2d');

    const renderPage = (num) => {
      pageRendering = true;
      pdfDoc.getPage(num).then((page) => {
        const viewport = page.getViewport({ scale });
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Render PDF page into canvas context
        const renderContext = { canvasContext, viewport };
        const renderTask = page.render(renderContext);

        // Wait for rendering to finish
        renderTask.promise.then(() => {
          pageRendering = false;
          if (pageNumPending !== null) {
            // New page rendering is pending
            renderPage(pageNumPending);
            pageNumPending = null;
          }
        });
      });
      $(this).find('.st-slide-media-page_num').text(num);
      // document.getElementById('page_num').textContent = num;
    };

    const queueRenderPage = (num) => {
      if (pageRendering) {
        pageNumPending = num;
      } else {
        renderPage(num);
      }
    };

    const onPrevPage = () => {
      if (pageNum <= 1) {
        return;
      }
      pageNum--;
      queueRenderPage(pageNum);
    };

    const onNextPage = () => {
      if (pageNum >= pdfDoc.numPages) {
        return;
      }
      pageNum++;
      queueRenderPage(pageNum);
    };

    $(this)
      .find('.st-slide-media-prev')[0]
      .addEventListener('click', onPrevPage);
    $(this)
      .find('.st-slide-media-next')[0]
      .addEventListener('click', onNextPage);

    const pdfUrl = $(this).data('pdf-src');
    console.log(`Loading ${pdfUrl}`);
    /**
     * Asynchronously downloads PDF.
     */
    pdfjsLib.getDocument(pdfUrl).promise.then((pdfDoc_) => {
      pdfDoc = pdfDoc_;
      $(this).find('.st-slide-media-page_count').text(pdfDoc.numPages);

      // Initial/first page rendering
      renderPage(pageNum);
    });
  });
};

export default initializeSlideAsPDF;
