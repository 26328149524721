import $ from 'jquery';

export default () => {
  $('.st-copy__cta').on('click', function (e) {
    e.preventDefault();
    const $this = $(this);
    const $copyParent = $this.closest('.st-copy');
    if (!$copyParent.length) return;
    const $copyText = $copyParent.find('.st-copy__text');
    if (!$copyText.length) return;

    $copyText.select();
    navigator.clipboard.writeText($copyText.text());
  });

  $('#st-copy-result-close').on('click', function (e) {
    e.preventDefault();
    let alertNode = document.getElementById('st-copy-result');
    alertNode.classList.add('d-none');
    alertNode.classList.remove('show', 'alert-success', 'alert-danger');
  });

  $('.st-copy__email').on('click', function (e) {
    e.preventDefault();
    const $this = $(this);
    let csrfToken = $('meta[name="csrf-token"]').attr('content');
    let courseType = $this.data('course-type');
    $.ajax({
      method: 'POST',
      url: 'dashboard/email_credentials',
      data: { course_type: courseType },
      headers: {
        'X-CSRF-TOKEN': csrfToken,
      },
    })
      .done(() => {
        let alertNode = document.getElementById('st-copy-result');
        if (alertNode) {
          alertNode.classList.remove('d-none', 'alert-danger');
          alertNode.classList.add('show', 'alert-success');
          document.getElementById('st-copy-result-text').innerHTML =
            'Email was sent.';
        }
      })
      .fail(() => {
        let alertNode = document.getElementById('st-copy-result');
        if (alertNode) {
          alertNode.classList.remove('d-none', 'alert-success');
          alertNode.classList.add('show', 'alert-danger');
          document.getElementById('st-copy-result-text').innerHTML =
            'A server error occurred and your email was not sent. Please, try again later.';
        }
      });
  });
};
