import $ from 'jquery';
import { Modal } from 'bootstrap';

const initializeCampaignPopup = () => {
  let elem = document.getElementById('afe_campaign_popup');
  if (!elem) return;
  let myModal = new Modal(elem);
  myModal.show();
  $('#afe_campaign_signup_btn').on('click', (e) => {
    e.preventDefault();
    let csrfToken = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
      method: 'POST',
      url: 'dashboard/afe_campaign_status',
      headers: {
        'X-CSRF-TOKEN': csrfToken,
      },
    }).always(() => {
      myModal.hide();
    });
  });
};

const initializeSurveyPopup = () => {
  let elem = document.getElementById('afe_survey_popup');
  if (!elem) return;
  let myModal = new Modal(elem);
  $('#afe_survey_success_message').hide();
  myModal.show();
  $('#afe_survey_status_checkbox').on('change', function () {
    if ($(this).is(':checked')) {
      $('#afe_survey_status_submit').removeAttr('disabled');
    } else {
      $('#afe_survey_status_submit').attr('disabled', true);
    }
  });
  $('#afe_survey_status_submit').on('click', (e) => {
    e.preventDefault();
    let csrfToken = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
      method: 'POST',
      url: 'dashboard/afe_survey_status',
      headers: {
        'X-CSRF-TOKEN': csrfToken,
      },
    }).done(() => {
      $('#afe_survey_success_message').show();
    });
  });
};

const initializeRenewalPopup = () => {
  let elem = document.getElementById('afe_renewal_popup');
  if (!elem) return;
  let myModal = new Modal(elem);
  $('#afe_renewal_success_message').hide();
  myModal.show();
  $('#afe_renewal_status_submit').on('click', (e) => {
    e.preventDefault();
    let csrfToken = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
      method: 'POST',
      url: 'dashboard/afe_renewal_status',
      headers: {
        'X-CSRF-TOKEN': csrfToken,
      },
    }).done(() => {
      $('#afe_renewal_success_message').show();
    });
  });
};

function handleBoxRating() {
  const rating = Number($(this).data('rating'));
  const question = $(this).data('question');
  $(`.u-st-box-rating[data-question="${question}"]`).removeClass('active');
  $(this).addClass('active');
  $(`input[name='${question}_box_rating']`).val(rating);
  handleCommentInput();
}

function handleCommentInput() {
  let requiredCommentsFilled = true;
  $('.student_eoy_comment').each(function () {
    if (!$(this).val()) {
      requiredCommentsFilled = false;
    }
  });
  if (
    $("input[name='first_box_rating']").val() &&
    $("input[name='second_box_rating']").val() &&
    requiredCommentsFilled
  ) {
    $('#eoy_survey_submit').attr('disabled', false);
  } else {
    $('#eoy_survey_submit').attr('disabled', true);
  }
}

const handleSubmitEoySurvey = (e) => {
  e.preventDefault();
  const csrfToken = $('meta[name="csrf-token"]').attr('content');
  const data = {
    first_box_rating: $("input[name='first_box_rating']").val(),
    second_box_rating: $("input[name='second_box_rating']").val(),
    first_box_comment: $("textarea[name='first_box_comment']").val(),
    second_box_comment: $("textarea[name='second_box_comment']").val(),
  };

  $.ajax({
    method: 'POST',
    url: '/dashboard/eoy_survey',
    data: data,
    headers: {
      'X-CSRF-TOKEN': csrfToken,
    },
  }).done(() => {
    $('#eoy_survey_content').hide();
    $('#eoy_survey_success_message').show();
  });
};

const initializeEoySurveyPopup = () => {
  let elem = document.getElementById('eoy_survey_popup');
  if (!elem) return;
  let myModal = new Modal(elem);
  $('#eoy_survey_submit').attr('disabled', true);
  $('#eoy_survey_success_message').hide();
  if (!sessionStorage.eoyPopupSeen) {
    myModal.show();
  }
  sessionStorage.eoyPopupSeen = true;

  $('.student_eoy_comment').on('input', handleCommentInput);
  $('#eoy_survey_submit').on('click', handleSubmitEoySurvey);
  $('.u-st-box-rating').on('click', handleBoxRating);
};

export default () => {
  initializeCampaignPopup();
  initializeSurveyPopup();
  initializeRenewalPopup();
  initializeEoySurveyPopup();
};
