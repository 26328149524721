import $ from 'jquery';

function watchEyeClick() {
  const $toggleEye = $('#st-eye-toggle');
  if (!$toggleEye.length) return;
  $toggleEye.click(function () {
    const $password = $('#user_password');
    if ($password.attr('type') === 'password') {
      $password.attr('type', 'text');
    } else {
      $password.attr('type', 'password');
    }
    $toggleEye.find('i').toggleClass('st-signin__eye--off st-signin__eye--on');
  });
}

export default () => {
  if (!$('.st-complete-signin-form').length) return;
  watchEyeClick();
};
