import $ from 'jquery';

function replaceOpacity($el, from, to) {
  let style = $el.attr('style');
  if (style) $el.attr('style', style.replace(from, to));
}

function addHoverEffect() {
  $('.st-dashboard-unit-card').hover(
    function () {
      replaceOpacity($(this), '.5', '.25');
    },
    function () {
      replaceOpacity($(this), '.25', '.5');
    }
  );
}

export default () => {
  if (!$('.st-dashboard-unit-card')) return;
  addHoverEffect();
};
