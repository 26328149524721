import $ from 'jquery';
import 'selectize';
const SEARCH_INTERVAL = 1000;

const inititializeSchoolSelector = () => {
  const element = $('#hour_of_ai_school_select');

  // Debounce didn't work here for some reason
  let shouldReload = true;
  const handleTyping = (txt) => {
    if (txt.length > 2 && shouldReload) {
      shouldReload = false;
      school_selector.load((cb) => {
        $.ajax({
          url: element.data('source'),
          data: { name: txt },
        })
          .done((res) => {
            setTimeout(SEARCH_INTERVAL, () => (shouldReload = true));
            cb(res);
          })
          .catch(console.error);
      });
    } else if (txt.length <= 2) {
      shouldReload = true;
    }
  };

  const handleValueChange = (v) => {
    if (v == -1) {
      $('.st-hour-of-ai-register__new-school-form').show();
      $('.st-hour-of-ai-register__new-school-form .st-form-required').attr(
        'required',
        true
      );
    } else {
      $('.st-hour-of-ai-register__new-school-form').hide();
      $(
        '.st-hour-of-ai-register__new-school-form .st-form-required'
      ).removeAttr('required');
    }
  };

  // Score default option as lowest possible score so it always shows up and is listed at the bottom
  const scoreFunc = (search) => {
    const score = school_selector.getScoreFunction(search);
    return (item) => {
      return item.value == -1 ? Number.MIN_VALUE : score(item);
    };
  };

  const defaultOptions = [
    {
      text: 'School not listed',
      value: -1,
    },
  ];

  const school_selector = element.selectize({
    onType: handleTyping,
    onChange: handleValueChange,
    options: defaultOptions,
    score: scoreFunc,
    sortField: 'value desc',
  })[0].selectize;
};

const initializeRegisterForm = () => {
  if (
    !$('#new_hour_of_ai_user').length &&
    !$('#new_brightest_futures_user').length
  )
    return;
  $('.u-selectize').selectize();
  inititializeSchoolSelector();
};

export default initializeRegisterForm;
