/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//
import Turbolinks from 'turbolinks';
import { Accordion, Alert, Carousel, Collapse, Modal } from 'bootstrap'; // eslint-disable-line
import $ from 'jquery';
import navShrinkOnScroll from 'nav/topNavBar';
import initializeAdditionalInfoForm from 'form/additionalInfoForm';
import initializeSubmitForm from 'form/applicationForm';
import initializeContactForm from 'form/contactus';
import initializeScheduleCall from 'form/scheduleCall';
import initializePopover from 'components/popover';
import initializeTooltips from 'components/tooltip';
import initializeCompleteSignInForm from 'form/completeSignIn';
import initializeCompleteSignUpForm from 'form/completeSignUp';
import initializeAccordionVideo from 'dashboard/accordionVideo';
import initializeDashboardCourses from 'dashboard/courses';
import initializeCopy from 'dashboard/copy';
import initializeLessonCards from 'dashboard/lessonCards';
import initializeUnitCards from 'dashboard/unitCards';
import initializeCampaignPopup from 'dashboard/campaignPopup';
import initializeRebootSurveyPopup from 'dashboard/rebootPopup';
import initializeNewEduIdPopup from 'dashboard/newEducatorIdPopup';
import initializeVideoPlayerPopup from 'dashboard/videoPlayerPopup';
import initializeRegisterForm from '../hour_of_ai/register';
import initializeAfeBenefitsSignupForm from '../afe_benefits/signup';
import initializeSlideAsPDF from '../pdf/slide';
import initializeAnnouncements from 'dashboard/announcements';
import 'regenerator-runtime/runtime';
import 'styles/application.scss';

require('@rails/ujs').start();
Turbolinks.start();
navShrinkOnScroll();

const ready = () => {
  document.querySelector('html').style.scrollBehavior = 'smooth';
  if (history.scrollRestoration) history.scrollRestoration = 'manual';
  initializeSubmitForm();
  initializeContactForm();
  initializePopover();
  initializeTooltips();
  initializeAdditionalInfoForm();
  initializeCompleteSignInForm();
  initializeCompleteSignUpForm();
  initializeScheduleCall();
  initializeAccordionVideo();
  initializeDashboardCourses();
  initializeLessonCards();
  initializeUnitCards();
  initializeCampaignPopup();
  initializeRebootSurveyPopup();
  initializeNewEduIdPopup();
  initializeVideoPlayerPopup();
  initializeCopy();
  initializeRegisterForm();
  initializeAfeBenefitsSignupForm();
  initializeSlideAsPDF();
  initializeAnnouncements();
};

$(document).on('turbolinks:load', ready);

document.addEventListener('turbolinks:before-visit', function () {
  document.querySelector('html').style.scrollBehavior = 'unset';
});

document.addEventListener('turbolinks:request-start', function (event) {
  var xhr = event.data.xhr;
  xhr.setRequestHeader(
    'X-Turbolinks-Nonce',
    $("meta[name='csp-nonce']").prop('content')
  );
});

document.addEventListener('turbolinks:before-cache', function () {
  $('script[nonce]').each(function (index, element) {
    $(element).attr('nonce', element.nonce);
  });
});
