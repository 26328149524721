import $ from 'jquery';
import 'selectize';
const checkBoxClass = 'st-afe-benefits-required-checkbox';

const makeCheckboxesRequired = () => {
  for (const el of document.getElementsByClassName(checkBoxClass)) {
    el.setCustomValidity('Please select a kit and/or membership');
    el.setAttribute('required', 'true');
  }
};

const makeCheckboxesNotRequired = () => {
  for (const el of document.getElementsByClassName(checkBoxClass)) {
    el.setCustomValidity('');
    el.removeAttribute('required');
  }
};

const initializeAfeBenefitsSignupForm = () => {
  if (!$('.st-afe-benefits-signup').length) return;
  $('.u-selectize').selectize();

  makeCheckboxesRequired();

  $(`.${checkBoxClass}`).on('change', function () {
    if ($(this).is(':checked')) {
      makeCheckboxesNotRequired();
    } else if (!$(`.${checkBoxClass}:checked`).length) {
      makeCheckboxesRequired();
    }
  });
};

export default initializeAfeBenefitsSignupForm;
