import $ from 'jquery';
import { Modal } from 'bootstrap';

const initializeNewEduIdPopup = () => {
  let elem = document.getElementById('newEduIdModal');
  if (!elem) return;
  let myModal = new Modal(elem);
  myModal.show();
  $('#submit-signup-form').click(() => $('#edit_user').submit());
};

export default () => {
  initializeNewEduIdPopup();
};
