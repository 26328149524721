import $ from 'jquery';

function hideEducatorFields() {
  $('.st-complete-signup-form__educator').fadeOut('fast');
  $('.st-complete-signup-form__educator').children().prop('required', false);
}

function showEducatorFields() {
  $('.st-complete-signup-form__educator').fadeIn('fast');
  $('.st-complete-signup-form__educator .u-form-input-required').prop(
    'required',
    true
  );
}

function toggleEducatorFields() {
  let selectedType = $('#user_type').val();
  if (selectedType == 'Coach' || selectedType == 'Administrator') {
    showEducatorFields();
  } else {
    hideEducatorFields();
  }
}

function watchUserType() {
  $('#user_type').on('change', () => {
    toggleEducatorFields();
  });
}

export default () => {
  if (!$('.st-complete-signup-form').length) return;
  watchUserType();
  toggleEducatorFields();
};
