import $ from 'jquery';

function updateStyle($el, newStyle) {
  $el.attr('style', newStyle);
}

function addHoverEffect() {
  $('.st-dashboard-lesson-card').hover(
    function () {
      const $el = $(this);
      const styleHover = $el.data('thumbnail');
      if (styleHover) updateStyle($el, styleHover);
    },
    function () {
      updateStyle($(this), '');
    }
  );
}

export default () => {
  if (!$('.st-dashboard-lesson-card')) return;
  addHoverEffect();
};
