import $ from 'jquery';

function showMoreCoursesButton() {
  $('#st-dashboard-show-more').on('click keypress', () => {
    $('.st-dashboard-course').removeClass('d-none');
    $('#st-dashboard-show-more').hide();
  });
  $('#st-dashboard-show-more-pd').on('click keypress', () => {
    $('.st-dashboard-course-pd').removeClass('d-none');
    $('#st-dashboard-show-more-pd').hide();
  });
}

export default () => {
  if (!$('.st-dashboard__course_card')) return;
  showMoreCoursesButton();
};
