import $ from 'jquery';

const NAVBAR_SHRINK_POS = 80;
const NAVBAR_SHRINK_CLS = 'st-navbar--shrink';
const SMOOTH_NAV_TIME = 300;

const navShrinkOnScroll = () => {
  let lastPosition = 0;
  let isTicking = false;

  const updateNavStyles = (scrollPos) => {
    if (document.getElementsByClassName('st-amazon__cta').length > 0) return;
    const el = document.getElementById('st-navbar');
    if (!el) return;
    if (scrollPos > NAVBAR_SHRINK_POS) {
      el.classList.add(NAVBAR_SHRINK_CLS);
    } else {
      el.classList.remove(NAVBAR_SHRINK_CLS);
    }
  };

  document.addEventListener('scroll', () => {
    lastPosition =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (!isTicking) {
      window.requestAnimationFrame(() => {
        updateNavStyles(lastPosition);
        isTicking = false;
      });
      isTicking = true;
    }
  });

  const announcements = document.getElementById('accordion-announcements');
  const navAnnouncements = document.querySelector('.nav-announcements');
  if (announcements && navAnnouncements) {
    announcements.addEventListener('hide.bs.collapse', function () {
      navAnnouncements.classList.remove('st-btn--nav-active');
    });
    announcements.addEventListener('show.bs.collapse', function () {
      navAnnouncements.classList.add('st-btn--nav-active');
    });
  }

  $('.st-smooth-scroll').on('click', function (event) {
    event.preventDefault();
    var $this = $(this);
    var elAttr = $this.attr('href');
    var offset = $this.attr('data-offset') ? $this.attr('data-offset') : 0;
    var fixedTop = $('.navbar.fixed-top');
    if (fixedTop && fixedTop.length) {
      offset = fixedTop.outerHeight();
    }
    $('body,html').animate(
      {
        scrollTop: $(elAttr).offset().top - offset,
      },
      SMOOTH_NAV_TIME
    );
  });
};

export default navShrinkOnScroll;
