import $ from 'jquery';

function stopVideosOnCollapse() {
  // jquery on is not working here, not clear why, but vanilla js does
  document
    .querySelector('.accordion')
    .addEventListener('hide.bs.collapse', function (e) {
      let $el = $(e.target);
      var iframes = $el.find('iframe');
      var videos = $el.find('video');
      iframes.each((_i, iframe) => {
        const contentWindow = iframe.contentWindow;
        if (/vimeo/.test(iframe.src) && contentWindow) {
          contentWindow.postMessage('{"method":"pause"}', '*');
        } else {
          if (/youtube|vimeo/.test(iframe.src)) {
            const iframeSrc = iframe.src;
            iframe.src = iframeSrc;
          }
        }
      });
      videos.each((_i, video) => video.pause());
    });
}

export default () => {
  if (!document.querySelector('.accordion')) return;
  stopVideosOnCollapse();
};
